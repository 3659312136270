import { get, update } from 'api/playlists'
import { deleteSlide, update as updateSlide } from 'api/slides'
import DeleteModal from 'components/DeleteModal'
import Grid from 'components/grid/Grid'
import LargeHeader from 'components/headers/LargeHeader'
import Loader from 'components/Loader'
import LoaderButton from 'components/LoaderButton'
import PreviewModal from 'components/PreviewModal'
import RefreshModal from 'components/RefreshModal'
import Tooltip from 'components/Tooltip'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  ArrowLeftCircle,
  ArrowRepeat,
  ArrowRightCircle,
  ClipboardX,
  Display,
  Pencil,
  Trash,
} from 'react-bootstrap-icons'
import { Typeahead } from 'react-bootstrap-typeahead'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import { Link, useParams } from 'react-router-dom'
import styles from './PlaylistEdit.module.scss'
import Slide from 'components/slide/Slide'

const maxCols = {
  landscape: 4,
  portrait: 6,
}

function PlaylistEdit(props) {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [duplicating, setDuplicating] = useState(false)
  const [playlist, setPlaylist] = useState({})
  const [slides, setSlides] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false)
  const [refreshOpen, setRefreshOpen] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(id)
      setPlaylist(res)
      setSlides(res.data.slides)
      setLoading(false)
    }

    fetchData()
  }, [id])

  const handleClickPreview = () => {
    setPreviewOpen(true)
  }

  const handleClickRefresh = () => {
    setRefreshOpen(true)
  }

  const moveUp = slide => {
    const index = slides.indexOf(slide)
    if (index !== 0) {
      moveSlide(index, index - 1, slide)
    }
  }

  const moveDown = slide => {
    const index = slides.indexOf(slide)
    if (index + 1 !== slides.length) {
      moveSlide(index, index + 1, slide)
    }
  }

  const moveSlide = (currentIndex, newIndex, slide) => {
    const newSlides = Array.from(slides)
    newSlides.splice(currentIndex, 1)
    newSlides.splice(newIndex, 0, slide)
    update(playlist.data.id, { slide_order: newSlides.map(x => x.id) })
    setSlides(newSlides)
    setRefreshed(false)
  }

  const handleDuplicate = async slideId => {
    setDuplicating(true)
    await update(playlist.data.id, { duplicate_slide: slideId })
    const res = await get(id)
    setSlides(res.data.slides)
    setDuplicating(false)
    setRefreshed(false)
  }

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deleteSlide(playlist.data.id, id)

    if (success) {
      const res = await get(playlist.data.id)
      setPlaylist(res)
      setSlides(res.data.slides)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setRefreshed(false)
    setDeleting(false)
  }

  const handleRefresh = () => {
    setRefreshing(true)
    update(playlist.data.id, {})
    setRefreshing(false)
    setRefreshed(true)
    setRefreshOpen(false)
  }

  const handleChangeOrientation = async orientation => {
    setLoading(true)
    await update(playlist.data.id, { orientation: orientation })
    const res = await get(id)
    setPlaylist(res)
    setSlides(res.data.slides)
    setLoading(false)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <Header playlist={playlist} />

      <div className='d-flex flex-wrap mb-3'>
        <Tooltip label='Add a new slide'>
          <Button
            className='mb-2 mr-1'
            variant='success'
            as={Link}
            to={`/create/${playlist.data.id}`}>
            Add new slide
          </Button>
        </Tooltip>

        <Tooltip label='Preview Playlist'>
          <Button className='mb-2 mr-1' variant='secondary' onClick={handleClickPreview}>
            <Display />
          </Button>
        </Tooltip>

        <Tooltip
          label={
            refreshed
              ? 'Refresh signal sent to displays, allow up to 10 minutes for displays to reload'
              : 'Refresh Displays'
          }>
          <Button
            className='mb-2 mr-sm-1 mr-0'
            disabled={refreshed}
            variant={'secondary'}
            onClick={handleClickRefresh}>
            <ArrowRepeat />
          </Button>
        </Tooltip>

        <ImageDisplayTime playlist={playlist} setRefreshed={setRefreshed} />
        <Orientation
          playlist={playlist}
          handleChangeOrientation={handleChangeOrientation}
          setRefreshed={setRefreshed}
        />
      </div>

      <Grid maxCols={maxCols[playlist.data.orientation]}>
        {slides.map((x, index) => (
          <div key={x.id} className='d-flex flex-column'>
            <div className={`${styles.info} mt-2`}>
              <div className='d-flex align-items-center justify-content-start'>
                {index !== 0 && (
                  <ArrowLeftCircle
                    className='round-icon-button'
                    onClick={() => moveUp(x)}
                  />
                )}
              </div>
              <SlideHeader slide={x} />
              <div className='d-flex align-items-center justify-content-end'>
                {index + 1 !== slides.length && (
                  <ArrowRightCircle
                    className='round-icon-button'
                    onClick={() => moveDown(x)}
                  />
                )}
              </div>
            </div>

            <Slide
              resource={
                x.type === 'iframe'
                  ? { url: x.iframe_url, resource_type: 'iframe' }
                  : x.base_layer_media
              }
              orientation={playlist?.data?.orientation}
              resourceOverlay={x.overlay_media}
              displayBadges={false}
              overlay={
                <div
                  className={styles.overlay}
                  style={{
                    flexDirection:
                      playlist.data.orientation === 'landscape' ? 'row' : 'column',
                  }}>
                  <Tooltip label='Edit this slide'>
                    <Button
                      className='mr-1 px-4 mb-1'
                      as={Link}
                      to={`/create/${playlist.data.id}/${x.id}`}>
                      <Pencil />
                    </Button>
                  </Tooltip>
                  <Tooltip label='Duplicate this slide, the new slide will be at the end of the playlist.'>
                    <LoaderButton
                      loading={duplicating}
                      label={<ClipboardX />}
                      className='mr-1 px-4 mb-1'
                      onClick={() => handleDuplicate(x.id)}
                    />
                  </Tooltip>
                  {slides.length > 1 && (
                    <Tooltip label='Delete this slide'>
                      <Button
                        variant='danger px-4 mb-1'
                        onClick={() => handleOpenDeleteDialog(x.id, index)}>
                        <Trash />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              }
            />

            {slides.length > 1 && (
              <div className='mt-2 mx-auto'>
                <Tooltip label='Select position in playlist'>
                  <Typeahead
                    className={styles.typeahead}
                    id='position'
                    onChange={selected => {
                      const newIndex = selected[0] - 1
                      if (newIndex !== index) {
                        moveSlide(index, newIndex, x)
                      }
                    }}
                    options={slides.map((_, i) => `${i + 1}`)}
                    placeholder={`Position ${index + 1}`}
                    selected={[]}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        ))}
      </Grid>

      <PreviewModal
        open={previewOpen}
        onHide={() => {
          setPreviewOpen(false)
        }}
        url={playlist.data.preview_url}
        orientation={playlist.data.orientation}
        aspectRatio={playlist.data.aspect_ratio}
      />

      <RefreshModal
        open={refreshOpen}
        onHide={() => {
          setRefreshOpen(false)
        }}
        loading={refreshing}
        onSubmit={handleRefresh}
        id={playlist.data.id}
      />

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
    </Container>
  )
}

function Header({ playlist }) {
  const [editing, setEditing] = useState(false)
  const [originalName, setOriginalName] = useState(playlist.data.name)
  const [name, setName] = useState(playlist.data.name)

  const handleChange = event => {
    setName(event.target.value)
  }

  const handleCancel = event => {
    setName(originalName)
    setEditing(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    setOriginalName(name)
    update(playlist.data.id, { name: name })
    setEditing(false)
  }

  if (editing) {
    return (
      <Form className='mb-5 mb-sm-0' onSubmit={handleSubmit}>
        <div className='d-flex flex-column flex-sm-row'>
          <Form.Group className='flex-grow-1 mr-sm-3'>
            <Form.Control
              type='text'
              name='playlist_name'
              placeholder='Playlist name'
              value={name}
              onChange={handleChange}
            />
          </Form.Group>

          <Button
            className='mr-sm-1 mb-1 mb-sm-0 h-100'
            onClick={handleCancel}
            variant='secondary'>
            Cancel
          </Button>

          <Button className='h-100' disabled={!name} variant='success' type='submit'>
            Save
          </Button>
        </div>
      </Form>
    )
  } else {
    return (
      <LargeHeader>
        <Tooltip label='Click to edit playlist name' placement='top'>
          <span
            className='cursor-pointer'
            onClick={() => {
              setEditing(!editing)
            }}>
            {name}
          </span>
        </Tooltip>
      </LargeHeader>
    )
  }
}

function ImageDisplayTime({ playlist, setRefreshed }) {
  const [value, setValue] = useState(playlist.data.image_display_seconds)

  const options = [5, 10, 15, 20, 25, 30, 45, 60]

  const handleChange = newValue => {
    setValue(newValue)
    update(playlist.data.id, { image_display_seconds: newValue })
    setRefreshed(false)
  }

  return (
    <Tooltip label='Videos will display for their full length, images will display based on this setting.'>
      <DropdownButton
        className='mr-1'
        name='display-images-for'
        title={`Display images for ${value} seconds`}>
        {options.map(x => (
          <Dropdown.Item key={x} onClick={() => handleChange(x)}>
            {x} seconds
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </Tooltip>
  )
}

function Orientation({ playlist, handleChangeOrientation, setRefreshed }) {
  const [value, setValue] = useState(playlist.data.orientation)

  const handleChange = newValue => {
    setValue(newValue)
    setRefreshed(false)
    handleChangeOrientation(newValue)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <Tooltip label='Set whether this content is to be display in landscape or portrait orientation.'>
      <DropdownButton name='orientation' title={`${capitalizeFirstLetter(value)}`}>
        <Dropdown.Item onClick={() => handleChange('landscape')}>Landscape</Dropdown.Item>
        <Dropdown.Item onClick={() => handleChange('portrait')}>Portrait</Dropdown.Item>
      </DropdownButton>
    </Tooltip>
  )
}

function SlideHeader({ slide }) {
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState(slide.name)

  const handleChange = event => {
    setName(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()

    const newName = name ? name : 'Untitled slide'
    setName(newName)
    updateSlide(slide.id, { name: newName })
    setEditing(false)
  }

  if (editing) {
    return (
      <Form onSubmit={handleSubmit} className='m-0 p-0'>
        <Form.Group className='m-0 p-0 mb-1'>
          <Form.Control
            style={{ height: '22px', textAlign: 'center' }}
            className='m-0 p-0'
            type='text'
            name='slide_name'
            placeholder='Slide name'
            value={name}
            onBlur={handleSubmit}
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
    )
  } else {
    return (
      <Tooltip label='Click to edit slide name' placement='top'>
        <div
          className={`${styles.count} cursor-pointer`}
          onClick={() => {
            setEditing(!editing)
          }}>
          {name}
        </div>
      </Tooltip>
    )
  }
}

export default PlaylistEdit
